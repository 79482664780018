export default {
  info1: "محتوى الأعمال",
  info2: "رؤيتنا",
  info3: "ما هي '1% فتيات'",
  info4: "معلومات عنا",
  info5: "اتصل بنا",
  some: "بعض المبدعين",
  partner: "الشركاء الرسميون",
  why: "لماذا",
  coreTitle: "حضانة فنان البث المباشر",
  coreContent:
    "<b>80%</b>من المؤثرين شهدوا زيادة في جمهورهم المباشر بنسبة <b>200%</b> متوسط  إيرادات البث المباشر بنسبة <b>50%</b>",
  coreP: "نركز على اكتشاف فناني البث المباشر المحتملين وتنميتهم.",
  whyTitle: "شبكة مؤثرة واسعة النطاق",
  whyH1: "اختر ما يهم عملك وعملائك",
  whyContent:
    "لقد أنشأنا اتصالات مع شبكة في منطقة الشرق الأوسط وشمال أفريقيا تضم أكثر من 1000 شخص مؤثر، تغطي 10 مناطق مثل دول مجلس التعاون الخليجي وتركيا ولبنان واليمن والمغرب، وأقمنا علاقة جيدة مع العديد من المنصات المباشرة.",
  aboutTitle:
    "أفضل وكالة متخصصة في البث المباشر عبر الإنترنت في منطقة الشرق الأوسط وشمال أفريقيا",
  aboutContent:
    "تأسست شركة 1% فتيات عام 2024 ويقع مقرها الرئيسي في دولة الإمارات العربية المتحدة، وهي شركة MCN الرائدة في مجال البث المباشر الاحترافي في منطقة الشرق الأوسط وشمال إفريقيا.",
  visionTitle1: "مجموعة النخبة",
  visionContent1:
    'تشير كلمة "الفتيات" إلى مجموعة مستهدفة تهيمن عليها الإناث أو أساسية. من خلال التركيز على دعم منشئي المحتوى من الإناث',
  visionTitle2: "تهيمن عليها الإناث",
  visionContent2:
    '"1%" تعني مفهوم العدالة والمساواة الاجتماعية. على الرغم من أن عددًا قليلاً فقط من الأشخاص يمكنهم أن يصبحوا من أفضل فناني البث المباشر، إلا أن الجميع لديهم الفرصة لتحقيق أحلامهم من خلال العمل الجاد والدعم.  ',
  visionTitle3: "الأهمية الاجتماعية",
  visionContent3:
    "يشير '1%' إلى مجموعة النخبة التي تهدف إلى تنمية ودعم أفضل المواهب في صناعة الترفيه.",
  place:
    "العنوان:Room 603, MM Tower, Deira  البريد الإلكتروني: HI{'@'}1%Girls.com",
  follow: "فلو اس",
  copy: "Copyright 2024 1%Girls . All Rights Reserved",
};

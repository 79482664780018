export default {
  info1: "Core Business",
  info2: "Our Vision",
  info3: "Why 1% Girls",
  info4: "About us",
  info5: "Contact Us",
  some: "Some Creators",
  partner: "Official Partners",
  why: "why",
  coreTitle: "Live streamers Incubation",
  coreContent:
    "<b>80%</b> KOL have seen their live audience grow <b>200%</b><br /><b>50%</b> by average live streaming revenue growth",
  coreP:
    "We are specializing in identifying and developing promising live streamers.",
  whyTitle: "Extensive influencer network",
  whyH1: "Choose What Matters To Your Business & Your Customers",
  whyContent:
    "We established connections with a MENA network of over 1000 influencers , covering 10 regions such as GCC, Turkey, Lebanon, Yemen, Morocco.",
  aboutTitle: "The top specialized online live \nStreaming agency in MENA",
  aboutContent:
    "Founded in 2024 and headquartered in the UAE. Global Team focus on professional talent training, popular recommendations, live streamers support.",
  visionTitle1: "Elite Group",
  visionContent1:
    '"Girls" indicates a female-dominated or core target group. Focused on supporting ',
  visionTitle2: "Female- dominated",
  visionContent2:
    'The "1%" implies the idea of social justice and equality. Although only a few people  ',
  visionTitle3: "Social Significance",
  visionContent3:
    "1% refers to the elite group, aiming to cultivate and support the top talents in the ",
  place: "Address:Room 603, MM Tower, DeiraE-mail: HI{'@'}1%Girls.com",
  follow: "Fllow Us",
  copy: "Copyright 2024 1%Girls . All Rights Reserved",
};

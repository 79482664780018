export default {
  info1: "İş İçeriği",
  info2: "Vizyonumuz",
  info3: "Neden %1 Kızlar",
  info4: "Hakkımızda",
  info5: "Bize Ulaşın",
  some: "İş İçeriği",
  partner: "Bazı Yaratıcılar",
  why: "Neden",
  coreTitle: "livestreamers Kuluçka",
  coreContent:
    "Yüzde<b>80</b> KOL canlı izleyicilerinin <b>%200</b>KOL canlı izleyicilerinin<b>50</b> oranında ortalama canlı yayın geliri artışı",
  coreP:
    "Gelecek vaat eden canlı yayıncıları belirleme ve geliştirme konusunda uzmanız.",
  whyTitle: "Kapsamlı influencer ağı",
  whyH1: "İşletmeniz ve Müşterileriniz İçin Önemli Olanı Seçin",
  whyContent:
    "GCC, Türkiye, Lübnan, Yemen, Fas gibi 10 bölgeyi kapsayan 1000'den fazla influencer'dan oluşan bir MENA ağıyla bağlantılar kurduk ve birkaç canlı platformla iyi bir ilişki kurduk.",
  aboutTitle: "MENA'daki en iyi uzman çevrimiçi canlı yayın ajansı",
  aboutContent:
    "GCC, Türkiye, Lübnan, Yemen, Fas gibi 10 bölgeyi kapsayan 1000'den fazla influencer'dan oluşan bir MENA ağıyla bağlantılar kurduk ve birkaç canlı platformla iyi bir ilişki kurduk.",
  visionTitle1: "Elit Grup",
  visionContent1:
    "Kızlar kadın ağırlıklı veya çekirdek hedef grubu belirtir. Desteklemeye odaklanmıştır  ",
  visionTitle2: "Kadın ağırlıklı",
  visionContent2:
    "%1 sosyal adalet ve eşitlik fikrini ima etmektedir. Her ne kadar sadece birkaç kişi  ",
  visionTitle3: "Sosyal Önem",
  visionContent3:
    "1 elit grubu ifade eder ve en iyi yetenekleri yetiştirmeyi ve desteklemeyi amaçlar. ",
  place: "Adres: Oda 603, MM Tower, Deira E-posta: HI{'@'}1%Girls.com",
  follow: "Bizi Takip Edin",
  copy: "Copyright 2024 1%Girls . All Rights Reserved",
};

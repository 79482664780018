<template>
  <div class="header flex-space">
    <img src="@/assets/logo.png" alt="" class="logo" />
    <div class="flex-space hidden">
      <div
        class="menu-btn"
        :class="{ active: menuOpen }"
        @click="(menuOpen = !menuOpen), (showLang = false)"
      ></div>
      <img src="@/assets/lo.png" alt="" style="max-height: 4.8rem" />
      <div
        class="picks flex-center"
        v-if="!showLang"
        @click="(showLang = true), (menuOpen = false)"
      >
        {{ langMap[locale] }}<img src="@/assets/down.png" alt="" />
      </div>
      <img
        src="@/assets/close.png"
        style="width: 4.8rem"
        v-else
        @click="showLang = false"
        alt=""
      />
    </div>

    <div class="flex guide" :class="{ active: menuOpen }" @click="menuOpen = false">
      <a href="#obj1" :class="{ active: activeIndex === 0 }" @click="activeIndex = 0">{{
        $t("info1")
      }}</a>
      <a href="#obj2" :class="{ active: activeIndex === 1 }" @click="activeIndex = 1">{{
        $t("info2")
      }}</a>
      <a href="#obj3" :class="{ active: activeIndex === 2 }" @click="activeIndex = 2">{{
        $t("info3")
      }}</a>
      <a href="#obj4" :class="{ active: activeIndex === 3 }" @click="activeIndex = 3">{{
        $t("info4")
      }}</a>
      <a href="#obj5" :class="{ active: activeIndex === 4 }" @click="activeIndex = 4">{{
        $t("info5")
      }}</a>
    </div>
    <select class="select" @change="onPicked">
      <option value="en">EN</option>
      <option value="tr">TR</option>
      <option value="al">لغة عربية</option>
    </select>
    <ul class="modal-me" v-if="showLang">
      <li @click="onPicked2('en')">EN（English）</li>
      <li @click="onPicked2('tr')">TR（Türkçe）</li>
      <li @click="onPicked2('al')">لغة عربية</li>
    </ul>
  </div>
  <swiper
    :modules="[Pagination]"
    :loop="true"
    :slides-per-view="1"
    :space-between="0"
    :autoplay="true"
    :pagination="{ clickable: true }"
  >
    <swiper-slide>
      <img src="@/assets/b1.png" alt="" class="img" />
    </swiper-slide>
    <swiper-slide>
      <img src="@/assets/b2.png" alt="" class="img" />
    </swiper-slide>
   
  </swiper>

  <div class="cont">
    <div class="box" id="obj1">
      <div class="title">{{ $t("info1") }}</div>
      <swiper
        :modules="[Pagination]"
        :loop="true"
        :slides-per-view="1"
        :space-between="15"
        :autoplay="false"
        class="chart"
        :pagination="{ clickable: true }"
        @active-index-change="changed"
      >
        <swiper-slide v-for="(item, index) in banner" :key="index">
          <div class="flex cores">
            <div class="flex-center">
              <img src="@/assets/play.png" class="core-img" alt="" />
              <span class="core-name">{{ $t("coreTitle") }}</span>
            </div>
            <div class="core-info" v-html="$t('coreContent')"></div>
          </div>
          <div class="flex p-bgs" v-if="realIndex === index">
            <div class="p-bg">
              <Percent :percent="0.8" :index="0" />
            </div>
            <div class="p-bg">
              <Percent :percent="2" :index="1" />
            </div>
            <div class="p-bg">
              <Percent :percent="0.5" :index="2" />
            </div>
          </div>

          <div class="core-s">
            <img src="@/assets/shadow.png" style="width: 100%" alt="" />
            <div class="core-p">
              {{ $t("coreP") }}
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>

    <div class="box">
      <div class="title">{{ $t("some") }}</div>
      <ul class="flex-space peos">
        <li class="peo">
          <img src="@/assets/h1.png" class="peo-img" alt="" />
          <div class="peo-name"><span>Safa/صفا Siddiqui</span></div>
        </li>
        <li class="peo">
          <img src="@/assets/h2.png" class="peo-img" alt="" />
          <div class="peo-name"><span>AJAKKAF RIME</span></div>
        </li>
        <li class="peo">
          <img src="@/assets/h3.png" class="peo-img" alt="" />
          <div class="peo-name"><span>AJAKKAF RIME</span></div>
        </li>
        <li class="peo">
          <img src="@/assets/h4.png" class="peo-img" alt="" />
          <div class="peo-name"><span>Bassant dinar</span></div>
        </li>
        <li class="peo">
          <img src="@/assets/h5.png" class="peo-img" alt="" />
          <div class="peo-name"><span>Deema Abu Naser</span></div>
        </li>
      </ul>
    </div>

    <div class="box">
	<ul class="flex-space peos">
	   <li class="peo2">
	   		  <video
	   			src="@/assets/2.mp4"
	   			class="video"
	   			controls
				  autoplay
				  muted
				  loop
	   		  ></video>
	   </li>
	   <li class="peo2" v-if="isDesktop">
	   		  <video
	   			src="@/assets/3.mp4"
	   			class="video"
	   			controls
	   		  ></video>
	   </li>
	 </ul>   
    </div>

    <div class="box">
      <div class="title">{{ $t("partner") }}</div>
      <ul class="flex-space">
        <li class="coco">
          <img src="@/assets/1.png" alt="" class="coco-img" />
        </li>
        <li class="coco">
          <img src="@/assets/2.png" alt="" class="coco-img" />
        </li>
        <li class="coco">
          <img src="@/assets/3.png" alt="" class="coco-img" />
        </li>
        <li class="coco">
          <img src="@/assets/4.png" alt="" class="coco-img" />
        </li>
		<li class="coco">
		  <img src="@/assets/5.png" alt="" class="coco-img" />
		</li>
		<li class="coco">
		  <img src="@/assets/6.png" alt="" class="coco-img" />
		</li>
      </ul>
    </div>

    <div class="box" id="obj3">
      <div class="title">{{ $t("why") }} <img src="@/assets/lo.png" alt="" /></div>
      <div class="flex-space" style="padding-bottom: 5%">
        <div class="rainbow color">{{ $t("whyTitle") }}</div>
        <div class="rain-info">
          <div class="rain-dt">{{ $t("whyH1") }}</div>
          <div class="rain-dd">{{ $t("whyContent") }}</div>
        </div>
        <div class="sia">
          <img src="@/assets/asia.png" class="plain" alt="" />
          <img src="@/assets/sha.jpg" class="sha" alt="" />
        </div>
      </div>
    </div>
  </div>

  <div class="about" id="obj4">
    <img src="@/assets/scene.png" class="scene" alt="" />
    <div class="cont">
      <div class="flex sppp" style="align-items: center">
        <div class="rain-white">{{ $t("info4") }}</div>
        <div class="rain-info sp">
          <div class="rain-dt">
            {{ $t("aboutTitle") }}
          </div>
          <div class="rain-dd">
            {{ $t("aboutContent") }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="cont">
    <div class="box" id="obj2">
      <div class="title">{{ $t("info2") }}</div>
      <ul class="flex-space vss">
        <li class="vs">
          <img src="@/assets/i1.png" alt="" />
          <div class="f30">{{ $t("visionTitle1") }}</div>
          <div class="f26">{{ $t("visionContent1") }}</div>
        </li>
        <li class="vs">
          <img src="@/assets/i2.png" alt="" />
          <div class="f30">{{ $t("visionTitle2") }}</div>
          <div class="f26">{{ $t("visionContent2") }}</div>
        </li>
        <li class="vs">
          <img src="@/assets/i3.png" alt="" />
          <div class="f30">{{ $t("visionTitle3") }}</div>
          <div class="f26">{{ $t("visionContent3") }}</div>
        </li>
      </ul>
    </div>
  </div>

  <div class="foot" id="obj5">
    <div class="cont">
      <div class="flex-space contact">
        <div class="c-info">
          <img src="@/assets/lo.png" alt="" />
          <div class="c-dt">{{ $t("info5") }}</div>
          <div class="c-dd">Address:Room 603, MM Tower</div>
          <div class="c-dd">Cooperation: collab@onepercentgirls.com</div>
          <div class="c-dd">Join us: hr@onepercentgirls.com</div>
        </div>

        <div class="w-list">
          <div class="c-dt">{{ $t("follow") }}</div>
          <div class="w-icon flex">
            <a
              href="https://www.facebook.com/profile.php?id=61558833839431"
              target="_blank"
              ><img src="@/assets/t6.png" alt=""
            /></a>
            <a href="#"><img src="@/assets/t1.png" alt="" /></a>
            <a href="#"><img src="@/assets/t2.png" alt="" /></a>
            <a href="#"><img src="@/assets/t3.png" alt="" /></a>
            <a href="#"><img src="@/assets/t4.png" alt="" /></a>
            <a href="#"><img src="@/assets/t5.png" alt="" /></a>
          </div>
        </div>
      </div>
      <div class="copy flex">{{ $t("copy") }}</div>
    </div>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import { ref } from "vue";
import Percent from "./percent.vue";
import { Pagination } from "swiper";
import { getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
const { locale } = useI18n();
const menuOpen = ref(false);
const showLang = ref(false);
const { $t } = getCurrentInstance().proxy;
const banner = ref([1, 2, 5, 3, 4]);
const activeIndex = ref(0);
const realIndex = ref(0);
const langMap = { en: "EN", tr: "TR", al: "لغة عربية " };
const changed = (e) => {
  realIndex.value = e.realIndex;
};

const onPicked = (e) => {
  locale.value = e?.target?.value;
  if (e?.target?.value === "al") {
    document.body.classList.add("other");
  } else {
    document.body.classList.remove("other");
  }
};
const onPicked2 = (e) => {
  locale.value = e;
  showLang.value = false;
  if (e === "al") {
    document.body.classList.add("other");
  } else {
    document.body.classList.remove("other");
  }
};	
</script>

<script>
export default {
  data() {
    return {
      isDesktop: true
    };
  },
  created() {
    this.checkDevice();
  },
  methods: {
    checkDevice() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      this.isDesktop = !/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    }
  }
};
</script>

<style>
.header {
  position: fixed;
  left: 0;
  right: 0;
  line-height: 11rem;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.16) 77.09%,
    rgba(0, 0, 0, 0) 100%
  );
  top: 0;
  z-index: 200;
  padding: 0 2.5%;
}
.guide > a {
  font-size: 1.8rem;
  color: #fff;
  margin: 0 4rem;
  white-space: nowrap;
}
.guide > a.active {
  background: linear-gradient(
    to right,
    rgba(216, 136, 208, 1),
    rgba(110, 197, 255, 1),
    rgba(124, 207, 214, 1)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "bold", "arial", sans-serif;
  font-weight: bold;
}
.color {
  background: linear-gradient(
    to right,
    rgba(216, 136, 208, 1),
    rgba(110, 197, 255, 1),
    rgba(124, 207, 214, 1)
  );
  -webkit-background-clip: text;
  font-weight: bold;
  font-family: "bold", "arial", sans-serif;
  -webkit-text-fill-color: transparent;
}
.cont {
  width: 160rem;
  margin: 0 auto;
}
.f30 {
  font-size: 3rem;
}
.f26 {
  font-size: 2.5rem;
}
.title {
  font-size: 5rem;
  line-height: 1.2;
  font-family: "bold", "arial", sans-serif;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6rem;
}
.box {
  padding-top: 12rem;
  justify-content: center; /* 水平居中 */
}
.peo {
  width: 24rem;
  position: relative;
}
.peo2 {
  width: 72rem;
  position: relative;
}
.peos .peo:nth-child(3) {
  width: 28rem;
}
.peos .peo:nth-child(3) .peo-name span {
  font-weight: bold;
  font-family: "bold", "arial", sans-serif;
  background: linear-gradient(
    to right,
    rgba(216, 136, 208, 1),
    rgba(110, 197, 255, 1),
    rgba(124, 207, 214, 1)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 5rem;
}
.peos .peo:nth-child(3)::after {
  width: 100%;
  padding-bottom: 100%;
  content: "";
  display: block;
  top: 0;
  left: 0;
  z-index: 20;
  background: url(@/assets/tm2.png) no-repeat;
  background-size: cover;
  position: absolute;
}

.peo-img {
  width: 100%;
  border-radius: 50%;
}
.peo-name {
  font-size: 4rem;
  text-align: center;
  padding-top: 10%;
}
.coco {
  width: 36rem;
  height: 15rem;
  line-height: 15rem;
  text-align: center;
}
.coco-img {
  max-width: 72%;
  max-height: 90%;
}
.video {
  background: #333;
  width: 100%;
}
.rainbow {
  width: 22rem;
  font-size: 4rem;
  line-height: 1.2;
  font-family: "bold", "arial", sans-serif;
  font-weight: bold;
}
.about {
  position: relative;
  background: url(@/assets/bg.jpg) no-repeat;
  background-size: cover;
  padding: 14rem 0;
}
.scene {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
.sia {
  position: relative;
  width: 21.5rem;
}
.plain {
  position: relative;
  z-index: 1;
  width: 100%;
}
.sha {
  position: absolute;
  bottom: -50%;
  right: -120%;
  width: 400%;
  z-index: -1;
}
.cores {
  padding: 4rem 6rem;
  align-items: center;
  border-radius: 14rem;
  background: linear-gradient(
    145.67deg,
    rgba(216, 136, 208, 0.2) 0%,
    rgba(124, 207, 214, 0.2) 100%
  );
}
.core-img {
  width: 12rem;
}
.core-name {
  font-size: 3.6rem;
  margin-left: 3rem;
  width: 32rem;
  font-weight: bold;
  font-family: "bold", "arial", sans-serif;
}
.select {
  -webkit-appearance: none;
  padding: 0.5rem 1rem;
  background: url(@/assets/caret.png) no-repeat right center;

  border: none;
  font-size: 1.8rem;
  color: #fff;
}
.select option {
  color: #333;
}
.core-info {
  flex: 1;
  font-size: 3rem;
  padding: 0 3rem;
}
.core-info b {
  background: linear-gradient(
    to right,
    rgba(216, 136, 208, 1),
    rgba(110, 197, 255, 1),
    rgba(124, 207, 214, 1)
  );
  -webkit-background-clip: text;
  font-weight: bold;
  font-family: "bold", "arial", sans-serif;
  -webkit-text-fill-color: transparent;
}
.core-s {
  position: relative;
  margin-bottom: 6rem;
}
.core-p {
  position: absolute;
  left: 0;
  top: 20%;
  width: 100%;
  text-align: center;
  display: flex;
  height: 80%;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  color: rgba(100, 102, 107, 1);
}
.title img {
  margin-left: 2rem;
  margin-right: 2rem;
  max-height: 6rem;
}
.rain-info {
  width: 88rem;
}
.rain-dt {
  font-size: 3rem;
  /* font-weight: bold; */
  font-family: "bold", "arial", sans-serif;
  white-space: pre-wrap;
}
.rain-dd {
  font-size: 2.6rem;
  color: rgba(100, 102, 107, 1);
  padding-top: 5rem;
  line-height: 3rem;
}
.rain-info.sp {
  position: relative;
  z-index: 20;
  color: #fff;
  width: 85rem;
  margin-left: 35rem;
}
.rain-info.sp .rain-dd {
  color: rgba(255, 255, 255, 1);
  opacity: 0.5;
}

.rain-white {
  position: relative;
  z-index: 20;
  font-size: 5rem;
  color: #fff;
}
.vs {
  width: 50rem;
  text-align: center;
}
.vs img {
  width: 13rem;
}
.vs .f30 {
  font-weight: bold;
  font-family: "bold", "arial", sans-serif;
  padding: 2.5rem 0;
}
.vs .f26 {
  color: rgba(100, 102, 107, 1);
}
.foot {
  background: linear-gradient(163.18deg, #ffeefd, #defcff);
  margin-top: 12rem;
}
.vss {
  align-items: flex-start !important;
}
.contact {
  padding: 8rem 0 6rem;
  align-items: flex-end !important;
}
.c-info {
  width: 31rem;
}
.c-info > img {
  max-height: 5rem;
}
.c-dt {
  font-size: 2.2rem;
  font-weight: bold;
  font-family: "bold", "arial", sans-serif;
  text-transform: uppercase;
  margin-top: 5rem;
}
.c-dd {
  margin-top: 2rem;
  font-size: 1.8rem;
  font-weight: 500;
  color: rgba(100, 102, 107, 1);
}
.w-icon {
  margin-top: 2rem;
}
.w-list {
  text-align: right;
}
.w-list img {
  width: 4rem;
}
body.other .w-list {
  text-align: left;
}
.core-name {
  font-size: 3.2rem;
}
body.other .core-name {
  margin-left: 0;
  margin-right: 3rem;
}
body.other .scene {
  left: auto;
  right: 0;
  transform: rotateY(180deg);
}
body.other .rain-info.sp {
  margin-left: 0;
  margin-right: 35rem;
}
body.other .w-icon a {
  margin-left: 0;
  margin-right: 2rem;
}
.w-icon a {
  margin-left: 2rem;
}
.copy {
  font-size: 1.8rem;
  color: rgba(100, 102, 107, 1);
  border-top: 1px solid rgba(220, 226, 227, 1);
  padding: 2.7rem 0;
}
.p-bgs {
  align-items: center;
  justify-content: space-around;
  margin-top: 7rem;
  margin-bottom: -7rem;
}
.img {
  width: 100%;
  height: 80rem;
  object-fit: cover;
}
.p-bg {
  position: relative;
  animation: none;
  opacity: 0;
}
.swiper-slide-active .p-bgs > .p-bg:first-child {
  opacity: 1;
  animation: bounceIn 1s 0s ease-out both;
}
.swiper-slide-active .p-bgs > .p-bg:nth-child(2) {
  opacity: 1;
  animation: bounceIn 1s 1s ease-out both;
}
.swiper-slide-active .p-bgs > .p-bg:nth-child(3) {
  opacity: 1;
  animation: bounceIn 1s 2s ease-out both;
}
.logo {
  max-height: 5rem;
}
.hidden {
  display: none !important;
}
@keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@media screen and (max-width: 750px) {
  html {
    font-size: 10px !important;
  }
  .cont {
    width: 67rem;
  }
  .title {
    font-size: 4rem;
  }
  .box {
    padding-top: 10rem;
  }
  .flex,
  .flex-center,
  .flex-space {
    flex-wrap: wrap;
  }
  .core-info {
    font-size: 2.8rem;
    margin-top: 4rem;
    padding: 0;
  }
  .cores {
    border-radius: 4rem;
  }
  .p-bgs {
    margin-bottom: 1rem;
  }
  .core-s > img {
    height: 18rem;
  }
  .core-p {
    font-size: 2.8rem;
    padding: 0 10%;
  }
  .peos {
    justify-content: space-around !important;
  }
  .peo {
    width: 16rem !important;
    margin-bottom: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .peo-name {
    font-size: 3rem !important;
  }

  .peos .peo:nth-child(3) .peo-name span {
    font-size: 3rem;
    font-weight: normal;
    font-family: apple;
    -webkit-text-fill-color: unset;
  }
  .peos .peo:nth-child(3)::after {
    display: none;
  }
  .coco {
    width: 21rem;
    height: 10rem;
    line-height: 10rem;
  }
  .title img {
    max-height: 4rem;
  }
  .rainbow {
    width: 100%;
    font-size: 3.6rem;
    text-align: center;
    margin-bottom: 2rem;
  }
  .rain-info {
    width: 40rem;
  }
  .sppp {
    justify-content: space-between;
  }
  .rain-info.sp {
    margin-left: 0;
    width: 31rem;
  }
  .about {
    padding: 4rem 0;
  }
  .scene {
    left: auto;
    right: 50%;
  }
  .rain-white {
    font-size: 4rem;
  }
  .vs {
    width: 100%;
    margin-bottom: 6rem;
  }
  .vs img {
    font-size: 16rem;
  }
  .foot {
    margin-top: 0;
  }
  .c-dt {
    font-size: 3.2rem;
    margin-top: 6rem;
    margin-bottom: 4rem;
  }
  .c-dd {
    font-size: 2.8rem;
  }
  .c-info {
    width: 100%;
  }
  .w-list {
    text-align: left;
  }
  .w-icon a {
    margin-left: 0;
    margin-right: 4rem;
  }
  .w-icon a img {
    width: 7rem;
  }
  .copy {
    font-size: 2.8rem;
    padding: 4rem 0;
  }
  .vs .f30 {
    font-size: 3.2rem;
    padding: 3rem 0;
  }
  .f26 {
    font-size: 2.8rem;
  }
  .rain-dt {
    font-size: 2.8rem;
  }
  .rain-dd {
    font-size: 2.8rem;
    line-height: 3.2rem;
    padding-top: 4rem;
  }
  body.other .w-list {
    text-align: right;
  }
  body.other .w-icon a {
    margin-right: 0;
    margin-left: 4rem;
  }
  body.other .rain-info.sp {
    margin-right: 0;
  }
  body.other .scene {
    right: auto;
    left: 50%;
  }
  .header {
    height: 8.8rem;
    line-height: 8.8rem;
    background: #fff;
    box-shadow: none;
    padding: 0 4rem;
  }
  .logo,
  .select {
    display: none;
  }
  .hidden {
    display: flex !important;
    width: 100%;
  }
  .menu-btn {
    width: 4.8rem;
    height: 4.8rem;
    background: url(@/assets/menu.png) no-repeat;
    background-size: 100%;
    cursor: pointer;
  }
  .menu-btn.active {
    background-image: url(@/assets/close.png);
  }
  .picks {
    font-size: 3.2rem;
    color: rgba(100, 102, 107, 1);
  }
  .picks img {
    width: 4rem;
    margin-left: 1.4rem;
    margin-right: 1.4rem;
    flex-shrink: 0;
  }
  .guide {
    position: fixed;
    top: 8.8rem;
    bottom: 0;
    background: #fff;
    z-index: 300;
    left: 0;
    display: none !important;
    width: 100%;
  }
  .guide.active {
    display: block !important;
  }
  .guide > a {
    line-height: 12rem;
    height: 12rem;
    font-size: 3.2rem;
    color: rgba(30, 33, 33, 1);
    display: block;
    border-bottom: 1px solid #ddd;
    position: relative;
  }
  .guide > a::after {
    background-image: url(@/assets/jr.png);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 4.8rem;
    content: "";
    position: absolute;
    display: block;
    height: 100%;
    width: 5rem;
    top: 0;
    right: 0;
  }

  body.other .guide > a::after {
    right: auto;
    left: 0;
    transform: rotateY(180deg);
  }
  .modal-me {
    position: fixed;
    top: 8.8rem;
    bottom: 0;
    background: #fff;
    z-index: 300;
    left: 0;
    width: 100%;
    padding: 0 4rem;
  }
  .modal-me > li {
    line-height: 12rem;
    height: 12rem;
    font-size: 3.2rem;
    color: rgba(30, 33, 33, 1);
    display: block;
    border-bottom: 1px solid #ddd;
    text-align: center;
  }
  .img {
    height: 40rem;
    margin-top: 8.8rem;
  }
  .swiper-slide-active .p-bgs > .p-bg:nth-child(3) {
    display: none;
  }
}
</style>

<template>
  <canvas ref="obj" class="canvas" width="300" height="300"></canvas>
  <CountTo class="count" :startVal="0" :endVal="endValue" :duration="2000" suffix="%" />
</template>

<script setup>
import { ref, watch, defineProps, onMounted } from "vue";
const obj = ref();
import { CountTo } from "vue3-count-to";
const prop = defineProps(["percent", "index"]);
const endValue = ref(0);
const drawCircle = (_options) => {
  var options = _options || {}; //获取或定义options对象;
  options.angle = options.angle || 1; //定义默认角度1为360度(角度范围 0-1);
  options.color = "#fff"; //定义默认颜色（包括字体和边框颜色）;
  options.lineWidth = 20; //定义默认圆描边的宽度;
  options.lineCap = "round "; //定义描边的样式，默认为直角边，round 为圆角
  options.lineJoin = "round";
  var oBoxOne = obj.value;
  var sCenter = oBoxOne.width / 2; //获取canvas元素的中心点;
  var ctx = oBoxOne.getContext("2d");
  var nBegin = 1 * Math.PI; //定义起始角度;
  var nEnd = Math.PI * 2; //定义结束角度;
  var grd = ctx.createLinearGradient(0, 0, oBoxOne.width, 0); //grd定义为描边渐变样式;
  grd.addColorStop(0, "rgba(216, 136, 208, 1)");
  grd.addColorStop(0.5, "rgba(110, 197, 255, 1)");
  grd.addColorStop(1, "rgba(124, 207, 214, 1)");

  // ctx.textAlign = "center"; //定义字体居中;
  // ctx.font = "normal normal bold 8rem Arial"; //定义字体加粗大小字体样式;
  // ctx.fillStyle = grd;
  // ctx.lineCap = options.lineCap;
  // ctx.fillText(options.angle * 100 + "%", sCenter, 1.2 * sCenter); //设置填充文字;
  /*ctx.strokeStyle = grd;    //设置描边样式为渐变色;
    ctx.strokeText((options.angle * 100) + '%', sCenter, sCenter);    //设置描边文字(即镂空文字);*/
  ctx.lineCap = options.lineCap;
  ctx.strokeStyle = grd;
  ctx.lineWidth = options.lineWidth;

  ctx.beginPath(); //设置起始路径，这段绘制360度背景;
  ctx.strokeStyle = "#e1f5f7";
  ctx.arc(sCenter, sCenter, sCenter - options.lineWidth, -nBegin, nEnd, false);
  ctx.stroke();

  var imd = ctx.getImageData(0, 0, 240, 240);
  function draw(current) {
    //该函数实现角度绘制;
    ctx.putImageData(imd, 0, 0);
    ctx.beginPath();
    ctx.strokeStyle = grd;
    ctx.arc(
      sCenter,
      sCenter,
      sCenter - options.lineWidth,
      -nBegin,
      nEnd * current - nBegin,
      false
    );
    ctx.stroke();
  }

  var t = 0;
  var timer = null;
  function loadCanvas(angle) {
    //该函数循环绘制指定角度，实现加载动画;
    timer = setInterval(function () {
      if (t > angle) {
        draw(options.angle);
        clearInterval(timer);
      } else {
        draw(t);
        t += 0.02;
      }
    }, 30);
  }
  loadCanvas(options.angle); //载入百度比角度  0-1 范围;
  timer = null;
};

watch(
  () => prop.percent,
  (newValue) => {
    if (obj.value) {
      drawCircle({
        angle: newValue,
      });
    }
  }
);

onMounted(() => {
  setTimeout(() => {
    drawCircle({
      angle: prop.percent ?? 0,
    });
    endValue.value = 100 * (prop.percent ?? 0);
  }, 1000 * (prop.index ?? 0));
});
</script>

<style>
.canvas {
  width: 34rem;
  height: 34rem;
}
.count {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 9rem;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  background: linear-gradient(
    to right,
    rgba(216, 136, 208, 1),
    rgba(110, 197, 255, 1),
    rgba(124, 207, 214, 1)
  );
  -webkit-background-clip: text;
  font-family: "bold", "arial", sans-serif;
  -webkit-text-fill-color: transparent;
}
@media screen and (max-width: 750px) {
  .canvas {
    width: 20rem;
    height: 20rem;
  }
  .count {
    font-size: 5.2rem;
  }
}
</style>
